.swiper-slide {
  width: auto;
}

.swiper-slide {
  .card {
    background: none;
    border: none;
    width: 100px;
    height: 160px;
    text-align: center;
  }
}

.swiper-button-prev, .swiper-button-next {
  color: rgba($primary, .2);
}
