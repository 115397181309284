footer {
  .logo {
    width: 100px;
  }

  a {
    color: $white;

    &:hover {
      color: $gray-400;
    }
  }

  small {
    font-size: .75rem;
  }
}
