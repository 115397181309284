@import url("https://fonts.googleapis.com/css?family=Roboto+Mono");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

@import "~aos/src/sass/aos";

@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "~swiper/swiper-bundle.css";

// Global
section {
  padding: 6.25rem 0;
}

@import "partials/header";
@import "partials/services";
@import "partials/technologies";
@import "partials/about";
@import "partials/timeline";
@import "partials/products";
@import "partials/footer";

.card {
  .progress {
    background: none;
    box-shadow: none;
    width: calc(100% + 4.4rem);
    max-width: none;

    .progress-bar {
      width: 10%;
    }

    &.rtl {
      transform: rotateY(180deg);
    }

    &.pink {
      .progress-bar {
        background-color: $pink;
      }
    }

    &.orange {
      .progress-bar {
        background-color: $orange;
      }
    }
  }

  &:hover {
    .progress-bar {
      width: 90%;
      transition-duration: 2s;
    }
  }

}

body {
  //fix na scroll
  overflow-x: hidden;
}
